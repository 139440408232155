import React, { useEffect } from "react"
import { graphql } from "gatsby"

const WpPost = ({ data }: any) => {
  const { wpPost } = data

  useEffect(() => {
    const paragraphElements = document.querySelectorAll("p")
    const headingElements = document.querySelectorAll("h2")
    for (let i = 0, all = paragraphElements.length; i < all; i++) {
      if (paragraphElements[i].classList.length === 0) {
        paragraphElements[i].classList.add("p-main")
      }
    }
    for (let i = 0, all = headingElements.length; i < all; i++) {
      if (headingElements[i].classList.length === 0) {
        headingElements[i].classList.add("heading-scndry")
      }
    }
  }, [])

  return (
    <div id="blogg-post-page">
      <div className="heading-box">
        <h2 className="heading-primary">{wpPost.title}</h2>
        <time className="date-label">{wpPost.date}</time>
      </div>
      <div
        className="blogg-post"
        dangerouslySetInnerHTML={{ __html: wpPost.content }}
      ></div>
    </div>
  )
}

export default WpPost

export const query = graphql`
  query PostById($id: String) {
    wpPost(id: { eq: $id }) {
      __typename
      id
      title
      content
      date(formatString: "DD MMM YYYY")
      featuredImage {
        node {
          altText
          link
          localFile {
            childImageSharp {
              fixed {
                base64
                tracedSVG
                aspectRatio
                srcWebp
                srcSetWebp
                originalName
              }
            }
          }
        }
      }
    }
  }
`
